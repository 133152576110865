<template>
  <div class="add_apply">
    <div class="bx_item">
      <div class="fs-40">服务说明</div>
      <div class="box fs-28 rich-text" v-html="instype_explain"></div>
    </div>
    <template v-if="(isSelectIphone && formData.instype_id != 13) || showImg">
      <div class="bx_item">
        <div class="fs-40 mt-50">
          设备正面照<span style="color: #e86161">*</span>
        </div>
        <div class="box fs-28">
          <p>1.{{ exampleTip || '请在拨号界面输入*#06#串码或序列号界面拍照；'}}</p>
          <p>2.请参考示例图拍摄，确保手机拍摄完整，串码一致</p>
        </div>
      </div>
      <pic-uploader @change="(url) => (formData.tem_img = url)" style="padding-left: 15px" :example="exampleImg || require(`@/assets/images/examplePic/example${formData.type === '2' ? '13' : '1'
        }.png`)" :src="formData.tem_img"></pic-uploader>
    </template>
    <!-- *****表单区****** -->
    <div class="bx_item form_box">
      <div class="fs-40 mt-50">
        用户信息<span style="color: #e86161">*</span>
      </div>
      <div>
        <van-form validate-first @submit="onSubmit" class="form_wid">
          <!-- IMEI号 -->

          <scan-code title="IMEI号或序列号" placeholder="请输入手机IMEI码或扫码" v-model="formData.imei" maxlength="24"
            @myInput="imitInput">
          </scan-code>
          <!-- //权益类型 -->
          <fieldPiker label="权益类型" placeholder="请选择权益类型" title="权益类型选择" :columns="selectList.columns1"
            :selectValue="showName.instype_name" valueKey="instype_name"
            @getMessage="setSelectValue($event, 'instype_name')" @clickDiaShow="clickDiaShow('instype_name')">
          </fieldPiker>
          <!-- 档位 -->
          <!-- <fieldPiker v-if="formData.instype_id == 13" label="档位选择" placeholder="请选择档位" title="档位选择" :columns="selectList.columns11"
            :selectValue="showName.gear_name" valueKey="name"
            @getMessage="setSelectValue($event, 'gear_name')" @clickDiaShow="clickDiaShow('gear_name')">
          </fieldPiker> -->
          <select-search-cell v-if="formData.instype_id == 13" title="档位选择" placeholder="请选择档位" v-model="formData.gear"
            :options="selectList.columns11" id-key="id" label-key="name"></select-search-cell>
          <!-- 保卡卡密 -->

          <template v-if="!isHuaWei && !isIphoneSmart">
            <div style="display: flex;align-items: center;">
              <van-field class="form_wid" type="text" v-model="formData.card_secret" label="保卡卡密" label-width="15em"
                input-align="right" placeholder="请输入6-12位保卡卡密" @input="cardSecretInput(1)" />
              <!-- 新加：随机获取卡密 -->
              <span @click="getRandomCarmiShow" class="random_carmi">在线生成</span>
            </div>
            <van-field class="form_wid" v-if="boundInsurance1" type="text" v-model="formData.card_secret2"
              :label="'保卡卡密-' + boundInsurance1.instype_name" label-width="15em" input-align="right"
              placeholder="请输入6-12位保卡卡密" @input="cardSecretInput(2)" />
            <van-field class="form_wid" v-if="boundInsurance3" type="text" v-model="formData.card_secret3"
              :label="'保卡卡密-' + boundInsurance3.instype_name" label-width="15em" input-align="right"
              placeholder="请输入6-12位保卡卡密" @input="cardSecretInput(3)" />
            <van-field class="form_wid" v-if="boundInsurance8" type="text" v-model="formData.card_secret4"
              :label="'保卡卡密-' + boundInsurance8.instype_name" label-width="15em" input-align="right"
              placeholder="请输入6-12位保卡卡密" @input="cardSecretInput(4)" />
            <van-field class="form_wid" v-if="boundInsurance9" type="text" v-model="formData.card_secret5"
              :label="'保卡卡密-' + boundInsurance9.instype_name" label-width="15em" input-align="right"
              placeholder="请输入6-12位保卡卡密" @input="cardSecretInput(5)" />
            <van-field class="form_wid" v-if="boundInsurance10" type="text" v-model="formData.card_secret6"
              :label="'保卡卡密-' + boundInsurance10.instype_name" label-width="15em" input-align="right"
              placeholder="请输入6-12位保卡卡密" @input="cardSecretInput(6)" />

            <field-piker v-if="selectList.columns6.length" label="保卡卡号" title="请选择卡号" v-model="formData.card_num"
              placeholder="请选择卡号" label-width="10em" :columns="selectList.columns6"></field-piker>
            <field-piker v-if="boundInsurance1 && selectList.columns7.length"
              :label="'保卡卡号-' + boundInsurance1.instype_name" title="请选择卡号" v-model="formData.card_num2"
              placeholder="请选择卡号" label-width="10em" :columns="selectList.columns7"></field-piker>

            <field-piker v-if="boundInsurance3 && selectList.columns8.length"
              :label="'保卡卡号-' + boundInsurance3.instype_name" title="请选择卡号" v-model="formData.card_num3"
              placeholder="请选择卡号" label-width="10em" :columns="selectList.columns8"></field-piker>
            <field-piker v-if="boundInsurance3 && selectList.columns9.length"
              :label="'保卡卡号-' + boundInsurance3.instype_name" title="请选择卡号" v-model="formData.card_num4"
              placeholder="请选择卡号" label-width="10em" :columns="selectList.columns9"></field-piker>
            <field-piker v-if="boundInsurance9 && selectList.columns10.length"
              :label="'保卡卡号-' + boundInsurance9.instype_name" title="请选择卡号" v-model="formData.card_num5"
              placeholder="请选择卡号" label-width="10em" :columns="selectList.columns10"></field-piker>

          </template>

          <select-search-cell title="新机品牌" placeholder="请选择新机品牌" v-model="formData.brand_id"
            :options="selectList.columns2" id-key="brand_id" label-key="brand" @change="($event) => {
              setSelectValue($event, 'brand');
            }
              "></select-search-cell>

          <select-search-cell title="手机型号" placeholder="请输入查询或选择型号" v-model="formData.model_id"
            :options="selectList.columns3" id-key="model_id" label-key="model_name" @click.native="selectRepairer"
            @change="($event) => {
              setSelectValue($event, 'model_name');
            }
              "></select-search-cell>

          <!-- //手机规格 -->
          <fieldPiker label="手机规格" placeholder="请输入查询或选择规格" title="手机规格选择" :columns="selectList.columns4"
            :selectValue="this.showName.ram_size" valueKey="ram_size" @getMessage="setSelectValue($event, 'ram_size')"
            @click.native="selectRepairer2" @clickDiaShow="clickDiaShow('ram_size')">
          </fieldPiker>

          <!-- 姓名 -->
          <van-field class="form_wid" v-model="formData.name" label="姓名" input-align="right" placeholder="请输入机主姓名" />
          <!-- 身份证类型 -->
          <!-- <fieldPiker v-if="isSelectIphone && formData.instype_id != 13" label="身份证类型" placeholder="请选择身份证类型" title="请选择身份证类型"
            :columns="selectList.columns" :selectValue="showName.id_card_type" valueKey="id_card_type"
            @getMessage="setSelectValue($event, 'id_card_type')" @clickDiaShow="clickDiaShow('id_card_type')">
          </fieldPiker> -->
          <!-- <select-search-cell
            title="身份证类型"
            placeholder="请选择身份证类型"
            v-model="formData.id_card_type_name"
            :options="selectList.columns"
            id-key="id_card_type_name"
            label-key="id_card_type_name"
            @change="
              ($event) => {
                setSelectValue($event, 'id_card_type_name');
              }
            "
          ></select-search-cell> -->
          <!-- 身份证号 -->

          <!-- <scan-id-card-number v-if="isSelectIphone && formData.instype_id != 13" v-model="formData.id_card"
            :isLength="formData.id_card_type == 1 ? true : false"></scan-id-card-number> -->

          <!-- 顾客手机 -->
          <van-field class="form_wid" type="tel" v-model="formData.phone" label="手机号码" maxlength="11" input-align="right"
            placeholder="请输入11位机主手机号" />

          <!-- //网络制式 -->
          <fieldPiker v-if="this.$route.query.type === '2' &&
            !(formData.instype_id === 1 && !this.boundInsurance1)
            " label="网络制式" placeholder="请选择网络制式" title="网络制式选择" :columns="selectList.columns5"
            :selectValue="this.showName.net_name" valueKey="net_name" @getMessage="setSelectValue($event, 'net_name')"
            @clickDiaShow="clickDiaShow('net_name')">
          </fieldPiker>

          <money
            v-if="this.$route.query.type === '2' || formData.instype_id == 13"
            v-model="formData.bare_price"
            title="裸机价格"
            :placeholder="formData.instype_id === 1 || formData.instype_id === 5 || formData.instype_id === 12 || formData.instype_id === 13 ? '自动获取' : '请输入裸机价格'"
            @change="barePriceChange"
          />
          <!-- v-if="this.$route.query.type === '2'" -->
           <!-- && formData.instype_id != 13 -->

          <multiple-choice v-if="hasSubInsurance && subInsuranceList.length" title="子险种" :is-collapse="true"
            placeholder="请选择子险种" v-model="selectedSubInsurances" :options="subInsuranceList" id-key="son_id"
            name-key="son_name" required-key="is_require"></multiple-choice>

          <template v-if="isHuaWei || (isIphoneSmart && timeLimitType)">
            <template v-if="timeLimitType === 1 || timeLimitType === 3">
              <date-picker v-model="formData.term_time_start" title="碎屏生效时间" :min-date="new Date(minDate || currentTime)"
                :max-date="formData.term_time_end
                  ? new Date(formData.term_time_end)
                  : new Date(
                    currentTime.getTime() + 100 * 365 * 24 * 60 * 60 * 1000
                  )
                  " placeholder="请选择开始期限"></date-picker>

              <date-picker v-model="formData.term_time_end" title="碎屏结束时间"
                :min-date="new Date(formData.term_time_start || new Date())" :max-date="new Date(
                  currentTime.getTime() + 100 * 365 * 24 * 60 * 60 * 1000
                )
                  " placeholder="请选择结束期限"></date-picker>
            </template>
            <template v-if="timeLimitType === 2 || timeLimitType === 3">
              <date-picker v-model="formData.term_time_start2" title="延保生效时间"
                :min-date="new Date(minDate2 || currentTime)" :max-date="formData.term_time_end2
                  ? new Date(formData.term_time_end2)
                  : new Date(
                    currentTime.getTime() + 100 * 365 * 24 * 60 * 60 * 1000
                  )
                  " placeholder="请选择延保生效时间"></date-picker>

              <date-picker v-model="formData.term_time_end2" title="延保失效时间"
                :min-date="new Date(formData.term_time_start2 || new Date())" :max-date="new Date(
                  currentTime.getTime() + 100 * 365 * 24 * 60 * 60 * 1000
                )
                  " placeholder="请选择延保失效时间"></date-picker>
            </template>
          </template>

          <van-cell class="not-purchased-from-our-shop" title="非本店购机" v-if="(!needIphoneVerify && !isHuaWei && !isIphoneSmart) || isIphoneNNHX
            ">
            <van-switch v-model="formData.is_shop" size="20px" :active-value="1" :inactive-value="0"
              @change="isShopChange" />
          </van-cell>
          <!-- 蓉家保 -->
          <!-- <van-cell class="not-purchased-from-our-shop" title="蓉家保" v-if="showRong || (formData.instype_id_two[0]==='1' && formData.instype_id === 10)">
            <van-switch v-model="is_rong" size="20px" :active-value="1" :inactive-value="0" />
          </van-cell> -->
          <!-- 地址 -->
          <!-- <region-selector :isRong="true" v-if="is_rong === 1 && (showRong || (formData.instype_id_two[0]==='1' && formData.instype_id === 10)) " title="地区选择" v-model="region"
            @finish="regionChange"></region-selector>
          <van-field v-if="is_rong === 1 && (showRong || (formData.instype_id_two[0]==='1' && formData.instype_id === 10))" class="form_wid" v-model="rong_address2" label="顾客详细地址"
            input-align="right" placeholder="请输入顾客详细家庭住址" /> -->
          <!-- 上传图片********* -->
          <template v-if="needIphoneVerify || isIphoneNNHX || isIphoneSmart || isHuaWei">
            <section-title show-asterisk>
              {{
                isIphoneNNHX ? "沙漏验机截图" : "爱思助手/沙漏验机截图"
              }}</section-title>
            <notice class="mb-30">注：请上传{{
              isIphoneNNHX ? "沙漏验机截图" : "爱思助手/沙漏验机截图"
            }}点击【查看验机报告】后截图</notice>
            <pic-uploader @change="(url) => (formData.tem_elsie = url)" :src="formData.tem_elsie" :example="require(`@/assets/images/examplePic/example${isIphoneNNHX ? '8' : '17'
              }.png`)
              "></pic-uploader>
          </template>
          <!-- 上传视频 *******-->
          <template v-if="(formData.instype_id && (formData.instype_id != 2 || formData.instype_id != 4)) &&
            (isHuaWei ||
              isIphoneSmart ||
              needIphoneVerify ||
              formData.is_shop === 1 ||
              isIphoneNNHX)
            ">
            <section-title show-asterisk>上传视频</section-title>
            <notice style="margin-bottom: 20px">
              <p>
                1.{{
                  needIphoneVerify ? "" : "非本店购机"
                }}请拍摄含串码（含输入*#06#）的视频，输入含串码视频且手机前后面都需完整展示并且完整展示4个角；
              </p>
              <p>2.视频只能是小于300M的MP4文件</p>
              <video v-if="formData.instype_id == 13" controls width="320" height="250" :src="exampleVideo">
                <!-- <source src="/media/cc0-videos/flower.webm" type="video/webm" />
                <source src="/media/cc0-videos/flower.mp4" type="video/mp4" />
                Download the <a href="/media/cc0-videos/flower.webm">WEBM</a>or<a href="/media/cc0-videos/flower.mp4">MP4</a>video. -->
              </video>          
            </notice>
            <!-- <div class="box fs-28">
        <p>1.需要在拨号界面输入*#06#的过程，示例视频如下</p>
        <p class="mt-30">
        </p>
      </div> -->
            <video-uploader :src="formData.video" @change="(url) => {
              formData.video = url;
            }
              " :only-camera="!isHuaWei"></video-uploader>
          </template>

          <div class="access g-flex-ac g-flex-jc mt-20 mt-50">
            <img @click="protocol = true" v-if="protocol === false" src="../../assets/images/icon_gx_wx.png" alt="" />
            <img @click="protocol = false" v-if="protocol === true" src="../../assets/images/icon_gx.png" alt="" />
            <span class="ml-10" style="color: #999999">已阅读并接受</span>
            <span style="color: #577bb5" @click="clickProtocol">
              <span v-if="instype_name || this.showName.instype_name">《{{ this.showName.instype_name }}》</span>
              <span v-else>《碎屏服务》</span>
            </span>
          </div>
          <!-- 提交注册 -->
          <div class="btn_sub">
            <my-button class="confirm-btn" :type="protocol ? 'primary' : 'default'" :disabled="!protocol"
              native-type="submit">提交申请
            </my-button>
          </div>
        </van-form>
      </div>
    </div>
    <!-- 随机生成卡密弹框 -->
    <van-popup v-model="randomCarmiShow" round position="bottom">
      <van-picker title="在线生成卡密" show-toolbar :columns="randomCarmiColumns" @cancel="randomCarmiShow = !randomCarmiShow"
        @confirm="randomCarmiConfirm" />
    </van-popup>
    <!-- 协议弹框 -->
    <van-popup round v-model="showText">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="wra_tittle" v-if="this.showName.instype_name">
            {{ this.showName.instype_name }}服务协议
          </div>
          <div class="wra_tittle" v-else>碎屏服务协议</div>
          <div class="text rich-text" v-html="decodeHTML(instype_agree)"></div>
          <div class="wra_btn" @click="showText = false">知道了</div>
        </div>
      </div>
    </van-popup>
    <!-- <van-popup
          v-model="showText"
          closeable
          close-icon="close"
      >
        <div class="pop-wrapper">
          <div v-html="decodeHTML(instype_agree)" class="block"></div>
        </div>
      </van-popup> -->
  </div>
</template>

<script>
import { Toast } from "vant";
import { decodeHTML } from "@/common/filters";
import { getPhonePrice, getCardName, getCard, queryImei, selectGear } from "@/api";
import { getRegion2 } from "@/api/register";

export default {
  name: "addApply",
  computed: {
    decodeHTML() {
      return (str) => {
        return decodeHTML(str);
      };
    },
  },
  data() {
    return {
      isAuthority:true,
      // 苹果查询返回数据
      iphoneAuto: {},
      // 随机生成卡密
      randomCarmiShow: false,
      randomCarmiColumns: [],//员工对应商家的虚拟卡配置列表
      erroMsg: '',
      zifang_id: '',
      // ******************
      isSelectIphone: false,//手机型号是否选择了苹果15系列-->换成调接口返回auto_exa值不为1
      instype_name: "",
      showText: false,
      protocol: false, //协议阅读
      instype_agree:
        "1、为了您的手机提供全方面保障服务，当您的手机发生意外或想换机时，请根据理赔需求至理赔申请界面提交理赔申请;2、本服务仅针对购买/激活日期未超过7天的安卓新机，有过拆修、进水历史的机器禁止投保。", //协议详情内容
      instype_explain:
        "<p>1.为您的设备提供保障服务，请根据理赔需求至申请界面提交理赔申请；</p>" +
        "<p>2.本服务仅针对购买/激活日期未超过7天的新设备；</p>" +
        "<p>3.有过拆修、进水历史的、展示机禁止注册相应服务；</p>", //保险说明

      exampleImg: "",
      exampleVideo: "",
      exampleTip: "",
      //下拉选择数组
      selectList: {
        columns: [
          { id_card_type: "大陆身份证", type: 1 },
          { id_card_type: "港澳身份证", type: 2 },
        ], //身份证类型
        columns1: [], //权益类型数组
        columns11: [], //档位数组
        columns2: [], //手机品牌数组
        columns3: [], //手机型号数组
        columns4: [], //手机规格数组
        columns5: [], //网络制式数组
        columns6: [], //卡号数组
        columns7: [], //卡号数组
        columns8: [], //卡号数组
        columns9: [], //卡号数组
        columns10: [], //卡号数组
      },
      //展示的下拉回显name
      showName: {
        instype_name: "", //权益类型（下拉输入框展示值）
        gear: "", // 档位（下拉输入框展示值）
        instype_id: "", //权益类型（下拉输入框展示值）
        brand: "", //品牌
        model_name: "", //品牌下型号
        ram_size: "", //规格
        net_name: "", //网络制式
        id_card_type: "大陆身份证", //身份证类型
      },
      region: '',//地址
      showRong: false,
      rong_address1: '',//地址
      rong_address2: '',//详细地址
      is_rong: 1,//蓉家保开关
      //提交上传参数
      formData: {
        type: this.$route.query.type, //类型
        instype_id: "", //权益类型id
        brand_id: "", //品牌id
        name: "", //机主姓名
        id_card_type: 1, //1 大陆身份证 2  港澳身份证
        id_card: "", //身份证号
        phone: "",
        imei: "",
        card_secret: "", //卡密
        card_secret2: "", //卡密
        card_secret3: "", //卡密
        card_secret4: "", //卡密
        card_secret5: "", //卡密
        card_secret6: "", //卡密
        model_id: "", //型号
        ram_size: "", //内存
        net_name: "", //制式
        bare_price: "", //裸机价格
        tem_img: "",
        is_shop: 0,
        video: "",
        order_id: "",
        card_num: "",
        card_num2: "",
        card_num3: "",
        card_num4: "",
        card_num5: "",
        card_secret_two: "",
        card_num_two: "",
        two: 0,
        son_strs: "",
        term_time_start: "",
        term_time_end: "",
        term_time_start2: "",
        term_time_end2: "",
        tem_elsie: "",
        instype_id_two: [],
        choose: 0,
      },
      minDate: "",
      minDate2: "",
      currentTime: new Date(),
      timeOut: null,
      hasSubInsurance: false, //是否有子保险
      subInsuranceList: [], //子保险列表
      selectedSubInsurances: [], //选中的子保险
      selectedSubInsurancesStr: "", //回显的选中子保险(返回的id字符串)
      isHuaWei: false,
      needIphoneVerify: false,
      timeLimitType: 0, //华为期限类型 1 碎屏 2 延保 3 碎屏+延保
      boundInsurance1: null, //双保险
      boundInsurance3: null, //双保险
      boundInsurance8: null, //双保险
      boundInsurance9: null, //双保险
      boundInsurance10: null, //双保险
      isIphoneNNHX: false, //苹果年年换新
      isIphoneSmart: false, //苹果智选
      showImg: false,
    };
  },
  watch: {
    // showName(valNew,oldNew){
    //   console.log(valNew,oldNew,'valNew,oldNew')
    //   this.getPhonePrice()
    // },
    "showName.ram_size": {
      handler: function () {
        if (
          this.formData.brand_id &&
          this.formData.model_id &&
          this.showName.ram_size
        ) {
          this.getPhonePrice();
        }
      },
    },
    "formData.model_id": {
      handler: function () {
        if (
          this.formData.brand_id &&
          this.formData.model_id &&
          this.showName.ram_size
        ) {
          this.getPhonePrice();
        }
      },
    },
    "formData.brand_id": {
      handler: function () {
        if (
          this.formData.brand_id &&
          this.formData.model_id &&
          this.showName.ram_size
        ) {
          this.getPhonePrice();
        }
      },
    },
    selectedSubInsurances: {
      deep: true,
      handler(list) {
        let hasSP = false; //有碎屏
        let hasYB = false; //有延保
        if (list.length) {
          list.forEach((item) => {
            if (item.son_name.indexOf("延保") !== -1) {
              hasYB = true;
            }
            if (item.son_name.indexOf("碎屏") !== -1) {
              hasSP = true;
            }
          });
        }
        if (hasSP && hasYB) {
          this.timeLimitType = 3;
        } else if (hasYB && !hasSP) {
          this.timeLimitType = 2;
          this.formData.term_time_start = "";
          this.formData.term_time_end = "";
        } else if (hasSP && !hasYB) {
          this.timeLimitType = 1;
          this.formData.term_time_start2 = "";
          this.formData.term_time_end2 = "";
        } else {
          this.timeLimitType = 0;
          // this.formData.term_time_start = ''
          // this.formData.term_time_end = ''
          // this.formData.term_time_start2 = ''
          // this.formData.term_time_end2 = ''
        }
      },
    },
  },
  mounted() {
    if (this.$route.query.type === "2") {
      this.isSelectIphone = true
    }
    let applyInfo = sessionStorage.getItem("applyInfo");
    if (applyInfo) {
      let data = JSON.parse(applyInfo);
      console.log(data);
      // // 传值
      // // 手机型号是否选择了苹果15系列
      // if (data.order.model_name.includes('iPhone 15')) {
      //   this.isSelectIphone = false
      // } else {
      //   this.isSelectIphone = true
      // }
      this.formData.type = data.order.type;
      this.formData.order_id = data.order.order_id;
      this.formData.instype_id = data.insurance_type.instype_id;
      this.formData.brand_id = data.order.brand_id;
      this.formData.name = data.order.name;
      this.formData.id_card = data.order.id_card;
      this.formData.id_card_type = data.order.id_card_type;
      this.formData.phone = data.order.phone;
      this.formData.imei = data.order.imei;
      this.formData.card_secret = data.order.card_secret;
      this.formData.model_id = data.order.model_id;
      this.formData.ram_size = data.order.ram_size;
      this.formData.net_name = data.order.net_name;
      this.formData.bare_price = data.order.bare_price;
      this.formData.tem_img = data.order.tem_img;
      this.formData.is_shop = data.order.is_shop;
      this.formData.gear = data.order.gear;
      this.formData.term_time_start = this.transformTime(
        data.order.term_time_start
      );
      this.formData.term_time_end = this.transformTime(
        data.order.term_time_end
      );
      this.formData.term_time_start2 = this.transformTime(
        data.order.term_time_start2
      );
      this.formData.term_time_end2 = this.transformTime(
        data.order.term_time_end2
      );
      this.formData.tem_elsie = data.order.tem_elsie;
      this.formData.video = data.order.video;
      // 下拉展示回显
      this.showName.instype_id = data.order.instype_id;
      this.showName.instype_name = data.insurance_type.instype_name;
      this.showName.brand = data.order.brand;
      this.showName.model_name = data.order.model_name;
      this.showName.ram_size = data.order.ram_size;
      this.showName.net_name = data.order.net_name;
      this.showName.id_card_type = data.order.id_card_type == 1 ? "大陆身份证" : "港澳身份证";
      this.showName.gear = data.order.gear
      this.showName.gear_name = data.order.gear_name
      this.getPhoneModel();
      this.getGearList();
      // 手机规格
      this.getPhoneRam()
      //  保险说明回显
      this.instype_explain = data.insurance_type.instype_explain;
      this.instype_agree = data.insurance_type.instype_agree;
      console.log(data, "data");
      if (data.order.tem_elsie && this.$route.query.type === "1") {
        this.needIphoneVerify = true;
      }

      if (this.$route.query.type === "1" && data.order.instype_id === 4) {
        this.isIphoneNNHX = true;
      }

      this.isHuaWei = data.order.instype_id === 6;
      this.isIphoneSmart = data.order.instype_id === 7;

      if (data.insurance_type.is_son) {
        this.selectedSubInsurancesStr = data.order.son_strs;
        this.hasSubInsurance = true;
        this.getSubInsuranceList(true);
      }

      if (this.isHuaWei) {
        this.minDate = new Date(this.formData.term_time_start);
        this.minDate2 = new Date(this.formData.term_time_start2);
      }

      if (data.order.choose) {
        console.log(data.order);
        this.boundInsurance1 = {
          instype_name: "附加" + data.insurance_type.instype_name,
          instype_id: data.order.instype_id,
        };
        this.formData.choose = 1;
        this.formData.card_num2 = data.order.vice_card_num;
        this.formData.card_secret2 = data.order.vice_card_secret;
        this.getCardNum(2);
      }
    }
    console.log(this.$store.state.document_title);
    console.log(this.$route.query.type);
    Toast.setDefaultOptions({ duration: 1000 });
    this.insuranceType();
    this.getPhoneBrand();
  },
  methods: {
    // 随机生成卡密
    getRandomCarmiShow() {
      // 获取列表
      this.$http.getCardName({ instype_id: this.formData.instype_id }).then((res) => {
        console.log(res, "getCardName");
        // this.randomCarmiColumns = res.data
        let columns = []
        if (res.data) {
          columns = res.data.map((column) => ({
            text: column.zifangan,
            value: column.zifang_id,
          }))
        } else {
          columns = []
        }
        this.randomCarmiColumns = columns
        console.log(JSON.stringify(this.randomCarmiColumns))
        console.log(this.randomCarmiColumns, '6666666');
        this.erroMsg = res.msg || '所在商家暂无虚拟卡密'
        this.randomCarmiShow = true
      });
    },
    randomCarmiConfirm(item, index) {
      console.log(item, index, '8888');
      this.zifang_id = item.value
      // 随机生成卡密
      this.$http.getCard({ instype_id: this.formData.instype_id, zifang_id: this.zifang_id }).then(res => {
        console.log(res, '卡密22');
        this.randomCarmiShow = false
        this.formData.card_secret = res.data.card_secret
        setTimeout(() => {
          this.getCardNum(1)
          // 获取子险种
          this.getSubInsuranceList();
        }, 500);
      })
    },
    // *******************
    // 蓉家保地址
    regionChange() {
      // console.log(this.region, 'this.formData.rong_address1');
    },
    imitInput(e) {
      console.log(e, 'eeeeeeeeee');
      console.log(e.target.value, 'target.valueeeeeeeee');
      this.formData.imei = e.target.value;
    },
    transformTime(time) {
      let date;
      if (String(new Date(time)) === "Invalid Date") {
        date = new Date();
      } else {
        date = new Date(time);
      }
      return `${date.getFullYear()}-${(date.getMonth() + 1 + "").padStart(
        2,
        "0"
      )}-${(date.getDate() + "").padStart(2, "0")}`;
    },
    clickProtocol() {
      this.showText = true;
      console.log(this.instype_agree);
      // this.$myDialog({
      //   type: 'text',
      //   title: `${this.instype_name}换机保服务协议`,
      //   message: this.instype_agree,
      // })
    },
    //权益类型数据请求
    insuranceType() {
      this.$http.insuranceType({ type: this.$route.query.type }).then((res) => {
        this.selectList.columns1 = res.data;
      });
    },
    //品牌数组请求
    getPhoneBrand() {
      this.$http.getPhoneBrand({ type: this.$route.query.type }).then((res) => {
        console.log(res, "getPhoneBrand");
        this.selectList.columns2 = res.data.brandList;
        // this.selectList.columns4 = res.data.ramList;
        this.selectList.columns5 = res.data.netList;
      });
    },
    // 手机型号下的手机规格
    getPhoneRam() {
      this.$http.getPhoneRam({ type: this.$route.query.type, model_id: this.formData.model_id }).then((res) => {
        console.log(res, "getPhoneRam");
        this.selectList.columns4 = res.data;
      });
    },
    //获取手机裸机价格
    getPhonePrice() {
      // if (this.$route.query.type === "2") {
      //   return;
      // }
      // if (this.formData.instype_id != 13) return
      const params = {
        brand_id: this.formData.brand_id,
        model_id: this.formData.model_id,
        ram_size: this.showName.ram_size,
        instype_id: this.formData.instype_id
      };
      this.$http.getPhonePrice(params).then((res) => {
        console.log(res, "getPhoneBrand");
        this.formData.bare_price = res.data.price || "";
      });
    },
    //品牌下手机型号数组请求
    getPhoneModel() {
      // 修改-新增字段 instype_id 保险类型  card_secret 卡密
      this.$http
        .getPhoneModel({ brand_id: this.formData.brand_id, instype_id: this.formData.instype_id, card_secret: this.formData.card_secret })
        .then((res) => {
          console.log(res, "getPhoneBrand");
          this.selectList.columns3 = res.data;
        });
    },
    clickDiaShow(val) {
      // console.log(this.selectList.columns11)
      if (val === "model_name" && !this.formData.brand_id) {
        Toast("请先选择手机品牌");
      }
    },
    selectRepairer() {
      if (!this.formData.brand_id) {
        this.$toast("请先选择手机品牌");
      }
    },
    selectRepairer2() {
      if (!this.formData.model_id) {
        this.$toast("请先选择手机型号");
      }
    },
    clearCardSecret(type) {
      //全清除
      if (type === 1) {
        // this.selectList.columns6 = [];
        this.selectList.columns7 = [];
        this.selectList.columns8 = [];
        this.selectList.columns9 = [];
        this.selectList.columns10 = [];
        // this.formData.card_num = "";
        this.formData.card_num2 = "";
        this.formData.card_num3 = "";
        this.formData.card_num4 = "";
        this.formData.card_num5 = "";
        // this.formData.card_secret = "";
        this.formData.card_secret2 = "";
        this.formData.card_secret3 = "";
        this.formData.card_secret4 = "";
        this.formData.card_secret5 = "";
        this.formData.card_secret6 = "";
        // this.formData.two = 0;
        this.boundInsurance1 = null;
        this.boundInsurance3 = null;
        this.boundInsurance8 = null;
        this.boundInsurance9 = null;
        this.boundInsurance10 = null;
        //卡密1改变
      } else if (type === 2) {
        // this.selectList.columns6 = [];
        this.selectList.columns7 = [];
        // this.formData.card_num = "";
        this.formData.card_num2 = "";
        this.formData.card_secret2 = "";
        // this.boundInsurance = null;
        // this.boundInsurance3 = null;
        //卡密2改变
      } else if (type === 3) {
        this.selectList.columns8 = [];
        this.formData.card_num3 = "";
        this.formData.card_secret3 = "";
        // this.boundInsurance3 = null;
      } else if (type === 4) {
        this.selectList.columns9 = [];
        this.formData.card_num4 = "";
        this.formData.card_secret4 = "";
      } else if (type === 5) {
        this.selectList.columns10 = [];
        this.formData.card_num5 = "";
        this.formData.card_secret5 = "";
        // this.boundInsurance9 = null;
      }
      else if (type === 5) {
        // this.selectList.columns10 = [];
        this.formData.card_num6 = "";
        this.formData.card_secret6 = "";
        // this.boundInsurance9 = null;
      }
      // else if (type === 1) {
      //   this.selectList.columns7 = [];
      //   this.formData.card_num2 = "";
      // }
      else {
        this.selectList.columns6 = [];
        this.selectList.columns7 = [];
        this.selectList.columns8 = [];
        this.selectList.columns9 = [];
        this.selectList.columns10 = [];
        this.formData.card_num = "";
        this.formData.card_num2 = "";
        this.formData.card_num3 = "";
        this.formData.card_num4 = "";
        this.formData.card_num5 = "";
        this.formData.card_secret = "";
        this.formData.card_secret2 = "";
        this.formData.card_secret3 = "";
        this.formData.card_secret4 = "";
        this.formData.card_secret5 = "";
        this.formData.card_secret6 = "";
        this.formData.two = 0;
        this.boundInsurance1 = null;
        this.boundInsurance3 = null;
        this.boundInsurance8 = null;
        this.boundInsurance9 = null;
        this.boundInsurance10 = null;
      }
    },

    getGearList(){
      selectGear({ instype_id: this.formData.instype_id }).then(res => {
        const { select, img, video, tip } = res.data
        this.exampleImg = img
        this.exampleVideo = video
        this.exampleTip = tip
        this.$set(this.selectList, 'columns11', select)
        // this.selectList.columns11 = select
        this.isSelectIphone = true
      })
    },
    //确认选择
    setSelectValue(value, name) {
      console.log('setSelectValue确认选择', value, name)
      switch (name) {
        // 权益类型
        case "instype_name":
          if (this.$route.query.type === "1") {//苹果
            if (!this.formData.imei) {
              this.$toast("请先输入IMEI码或扫码");
              return;
            }
            if (value.instype_id != 13) {
              this.$http.queryImei({ imei: this.formData.imei }).then(res => {
                console.log(res, "queryImei");
                this.iphoneAuto = res.data
                if (res.data.auto_exa !== 1) {
                  this.isSelectIphone = true
                }else{
                  this.isSelectIphone = false
                }
                this.formData.brand_id = res.data.brand_id
                // 调手机型号接口
                this.$http
                  .getPhoneModel({ brand_id: this.formData.brand_id, instype_id: this.formData.instype_id, card_secret: this.formData.card_secret })
                  .then((res1) => {
                    console.log(res1, "getPhoneBrand");
                    this.selectList.columns3 = res1.data;
                    this.formData.model_id = res.data.model_id
                    // ***调手机规格接口
                    this.$http.getPhoneRam({ type: this.$route.query.type, model_id: this.formData.model_id }).then((res2) => {
                      console.log(res2, "getPhoneRam");
                      this.selectList.columns4 = res2.data;
                      if(res.data.ram_size){
                        this.showName.ram_size =res.data.ram_size
                       this.formData.ram_size =res.data.ram_size
                       this.getBarePrice();
                      }
                    });
                  });
              })
            }
          }
          
          console.log(value, name, 'value, name');
          // instype_id === 1:碎屏服务；instype_id === 10：全心保
          if (value.instype_id === 1 && this.$route.query.type == 1) {
            this.showRong = true
          } else {
            this.showRong = false
          }
          this.formData.choose = 0;
          this.clearCardSecret(1);

          this.hasSubInsurance = value.is_son === 1;

          if (this.isHuaWei) {
            this.formData.tem_img = "";
            this.formData.term_time_start = "";
            this.formData.term_time_end = "";
            this.formData.term_time_start2 = "";
            this.formData.term_time_end2 = "";
          }

          this.isHuaWei = value.instype_id === 6;
          this.isIphoneSmart = value.instype_id === 7;

          this.showName.selectValue = value.instype_name;
          this.instype_explain = value.instype_explain;
          this.instype_agree = value.instype_agree;
          this.showName.instype_name = value.instype_name;
          this.instype_name = value.instype_name;
          this.formData.instype_id = value.instype_id;

          if (value.instype_id == 13) {
            this.formData.is_shop = 1
            this.getGearList()
          } else {
            this.exampleImg = ''
            this.exampleVideo = ''
            this.exampleTip = ''
          }
          this.getSubInsuranceList();

          if (
            this.formData.instype_id === 1 ||
            this.formData.instype_id === 5 ||
            this.formData.instype_id === 6
          ) {
            this.getBarePrice();
          }

          this.isIphoneNNHX = !!(
            this.$route.query.type === "1" &&
            this.formData.instype_id === 4 &&
            this.formData.is_shop
          );

          break;
        case "gear_name":
          this.showName.gear_name = value.name
          this.formData.gear_name = value.name
          this.formData.gear = value.id
          break;
        case "brand":
          this.formData.model_id = "";
          this.getSubInsuranceList();

          this.getPhoneModel();
          this.getBarePrice();
          break;
        // 手机型号
        case "model_name":
          console.log(value, '15iphonevalue, name');
          console.log(value.model_name.includes('iPhone 15'), '66666666666666');
          // if (value.model_name.includes('iPhone 15')) {
          //   this.isSelectIphone = false
          // } else {
          //   this.isSelectIphone = true
          // }
          // this.showName.model_name = value.model_name
          // this.formData.model_id = value.model_id
          // 手机规格
          this.getPhoneRam()
          this.getSubInsuranceList();
          this.getBarePrice();
          break;
        case "ram_size":
          this.showName.ram_size = value.ram_size;
          this.formData.ram_size = value.ram_size;
          this.getBarePrice();
          console.log("ram_size");
          break;
        case "id_card_type":
          // this.showName.id_card_type_name = value.id_card_type_name;
          this.formData.id_card_type = value.type;
          this.formData.id_card = "";
          this.showName.id_card_type = value.id_card_type;
          break;
        default:
          this.showName.net_name = value.net_name;
          this.formData.net_name = value.net_name;
          break;
      }
      console.log(this.formData);
    },
    isShopChange() {
      if(this.iphoneAuto.auto_exa&&this.iphoneAuto.auto_exa===1){
        this.isSelectIphone = true
      }
      this.isIphoneNNHX = !!(
        this.$route.query.type === "1" &&
        this.formData.instype_id === 4 &&
        this.formData.is_shop
      );
      // this.needIphoneVerify = !!(
      //   this.$route.query.type === "1" &&
      //   (this.formData.instype_id === 1 ||
      //     this.formData.instype_id === 3 ||
      //     this.formData.instype_id === 5 ||
      //     this.formData.instype_id === 7 ||
      //     this.formData.instype_id === 8 ||
      //     this.formData.instype_id === 9)
      // );
    },

    cardSecretInput(type) {
      if (type == 1) {
        this.clearCardSecret(type);
      }

      if (this.timeOut) {
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }
      this.timeOut = setTimeout(() => {
        this.getCardNum(type);
        if (this.hasSubInsurance) {
          this.subInsuranceList = [];
          this.getSubInsuranceList();
        }
      }, 500);
    },
    getCardNum(type) {
      let instype_id = this.formData.instype_id;
      console.log(type, '哈哈哈哈哈哈哈哈哈哈哈');
      if (type === 1) {
        if (instype_id && this.formData.card_secret) {
          this.$http
            .checkCdkey({
              instype_id,
              card_secret: this.formData.card_secret,
              type: this.$route.query.type,
            })
            .then((res) => {
              this.selectList.columns6 =
                (res && res.data && res.data.list) || [];
              if (res && res.data) {
                if (res.data.two === 1) {
                  let boundInsuranceId = [];
                  boundInsuranceId = res.data.instype_id_two.split(",");

                  console.log(this.selectList.columns1);

                  let boundInsurance = [];
                  boundInsuranceId.map((item) => {
                    this.selectList.columns1.map((item2) => {
                      if (item2.instype_id == item) {
                        boundInsurance.push(item2);
                      }
                    });
                  });
                  console.log(boundInsuranceId);
                  console.log(boundInsurance);
                  // this.formData.instype_id_two = boundInsuranceId;
                  // console.log(boundInsurance);
                  this.formData.instype_id_two = [];
                  if (boundInsurance) {
                    let html2 = ``;
                    //  <input v-for='' type="checkbox" checked class="tongshi">是否需同时注册(${boundInsurance.instype_name})
                    boundInsurance.map((item) => {
                      html2 += ` <input  type="checkbox" class="myinput${item.instype_id}" value="${item.instype_id}">是否需同时注册(${item.instype_name}) <br>`;
                    });
                    let html = `<div class="tongshi">${html2}</div>`;
                    this.$myDialog({
                      type: "text",
                      title: "温馨提示",
                      message: html,
                      isShowCancel: true,
                      confirmButtonText: "是",
                      cancelButtonText: "否",
                      beforeClose: (action, done) => {
                        if (action === "confirm") {
                          // if (this.formData.instype_id_two.length == 3) {
                          //   this.boundInsurance = boundInsurance[0];
                          //   this.boundInsurance3 = boundInsurance[1];
                          //   this.boundInsurance9 = boundInsurance[2];
                          // } else {
                          //   this.formData.instype_id_two.map((item) => {
                          //     boundInsurance.map((item2) => {
                          //       if (item == item2.instype_id) {
                          //         this.boundInsurance = item2;
                          //       }
                          //     });
                          //   });
                          // }
                          this.formData.instype_id_two.map((item) => {
                            boundInsurance.map((item2) => {
                              if (item == item2.instype_id) {
                                this[`boundInsurance${item2.instype_id}`] =
                                  item2;
                              }
                            });
                          });
                          console.log(this.formData.instype_id_two, '啥哈哈水水水水水水水水水水');
                          // if (this.formData.instype_id_two.length) {
                          //   this.formData.two = 1;
                          // } else {
                          //   this.formData.two = 0;
                          // }
                          this.formData.two = 1;
                          done();
                        } else {
                          this.formData.instype_id_two = "";
                          this.formData.two = 0;
                          this.clearCardSecret(1);
                          done();
                        }
                      },
                    });
                    this.myselect();
                  }
                }
                if (res.data.choose === 1) {
                  this.$myDialog({
                    type: "text",
                    title: "温馨提示",
                    message: `是否需要购买碎屏险`,
                    isShowCancel: true,
                    confirmButtonText: "是",
                    cancelButtonText: "否",
                    beforeClose: (action, done) => {
                      if (action === "confirm") {
                        this.formData.choose = 1;
                        this.boundInsurance1 = {
                          instype_name: "附加" + this.instype_name,
                          instype_id: this.formData.instype_id,
                        };
                        done();
                      } else {
                        this.formData.choose = 0;
                        done();
                      }
                    },
                  });
                }
                if (res.data.is_img === 1) {
                  this.showImg = true
                }
              }
              if (instype_id === 1 || instype_id === 9) {
                this.needIphoneVerify =
                  res && res.data && res.data.is_ais === 1;
              }
            })
            .catch(() => {
              this.selectList.columns6 = [];
              this.formData.card_num = "";
              if (instype_id === 1 || instype_id === 9) {
                this.needIphoneVerify = false;
              }
            });
        } else {
          this.selectList.columns6 = [];
          this.formData.card_num = "";
          if (instype_id === 1 || instype_id === 9) {
            this.needIphoneVerify = false;
          }
        }
      } else if (type === 2) {
        if (this.formData.card_secret2) {
          this.$http
            .checkCdkey({
              instype_id: this.boundInsurance1.instype_id,
              card_secret: this.formData.card_secret2,
              type: this.$route.query.type,
            })
            .then((res) => {
              this.selectList.columns7 =
                (res && res.data && res.data.list) || [];
              if (instype_id === 1) {
                this.needIphoneVerify =
                  res && res.data && res.data.is_ais === 1;
              }
            })
            .catch(() => {
              // this.clearCardSecret(2);
              if (instype_id === 1) {
                this.needIphoneVerify = false;
              }
            });
        } else {
          this.clearCardSecret(2);
          if (instype_id === 1) {
            this.needIphoneVerify = false;
          }
        }
      } else if (type === 3) {
        if (this.formData.card_secret3) {
          this.$http
            .checkCdkey({
              instype_id: this.boundInsurance3.instype_id,
              card_secret: this.formData.card_secret3,
              type: this.$route.query.type,
            })
            .then((res) => {
              this.selectList.columns8 =
                (res && res.data && res.data.list) || [];
              if (instype_id === 1) {
                this.needIphoneVerify =
                  res && res.data && res.data.is_ais === 1;
              }
            })
            .catch(() => {
              // this.clearCardSecret(3);
              if (instype_id === 1) {
                this.needIphoneVerify = false;
              }
            });
        } else {
          // this.clearCardSecret(3);
          if (instype_id === 1) {
            this.needIphoneVerify = false;
          }
        }
      } else if (type === 4) {
        if (this.formData.card_secret4) {
          this.$http
            .checkCdkey({
              instype_id: this.boundInsurance8.instype_id,
              card_secret: this.formData.card_secret4,
              type: this.$route.query.type,
            })
            .then((res) => {
              this.selectList.columns9 =
                (res && res.data && res.data.list) || [];
              if (instype_id === 1) {
                this.needIphoneVerify =
                  res && res.data && res.data.is_ais === 1;
              }
            })
            .catch(() => {
              // this.clearCardSecret(3);
              if (instype_id === 1) {
                this.needIphoneVerify = false;
              }
            });
        } else {
          // this.clearCardSecret(4);
          if (instype_id === 1) {
            this.needIphoneVerify = false;
          }
        }
      } else if (type === 5) {
        if (this.formData.card_secret5) {
          this.$http
            .checkCdkey({
              instype_id: this.boundInsurance9.instype_id,
              card_secret: this.formData.card_secret5,
              type: this.$route.query.type,
            })
            .then((res) => {
              this.selectList.columns10 =
                (res && res.data && res.data.list) || [];
              if (instype_id === 1 || instype_id === 9) {
                this.needIphoneVerify =
                  res && res.data && res.data.is_ais === 1;
              }
            })
            .catch(() => {
              // this.clearCardSecret(3);
              if (instype_id === 1 || instype_id === 9) {
                this.needIphoneVerify = false;
              }
            });
        } else {
          // this.clearCardSecret(4);
          if (instype_id === 1 || instype_id === 9) {
            this.needIphoneVerify = false;
          }
        }
      } else if (type === 6) {
        if (this.formData.card_secret6) {
          this.$http
            .checkCdkey({
              instype_id: this.boundInsurance10.instype_id,
              card_secret: this.formData.card_secret6,
              type: this.$route.query.type,
            })
            .then((res) => {
              // this.selectList.columns11 =
              //   (res && res.data && res.data.list) || [];
              if (instype_id === 1) {
                this.needIphoneVerify =
                  res && res.data && res.data.is_ais === 1;
              }
            })
            .catch(() => {
              // this.clearCardSecret(3);
              if (instype_id === 1) {
                this.needIphoneVerify = false;
              }
            });
        } else {
          // this.clearCardSecret(4);
          if (instype_id === 1) {
            this.needIphoneVerify = false;
          }
        }
      }
    },
    barePriceChange() {
      this.getSubInsuranceList();
    },
    myselect() {
      let tongshi = document.querySelector(".tongshi");
      let that = this;
      tongshi.onclick = function () {
        // if (!event.target.checked) {
        //   that.boundInsurance = {};
        // }
        var mclass = event.target.className;
        if (!mclass.indexOf("myinput")) {
          console.log(
            document.querySelector("." + mclass).getAttribute("value")
          );
          if (document.querySelector("." + mclass).checked) {
            that.formData.instype_id_two.push(
              document.querySelector("." + mclass).getAttribute("value")
            );
          } else {
            that.formData.instype_id_two.map((item, index) => {
              if (
                document.querySelector("." + mclass).getAttribute("value") ==
                item
              ) {
                that.formData.instype_id_two.splice(index, 1);
              }
            });
          }
        }
      };
    },
    getBarePrice() {
      console.log(`%c-------裸机价格参数------`, "color:red");
      console.log("instype_id", this.formData.instype_id);
      console.log("type", this.$route.query.type);
      console.log("brand_id", this.formData.brand_id);
      console.log("model_id", this.formData.model_id);
      console.log("ram_size", this.formData.ram_size);
      console.log(`%c------------------------`, "color:red");
      this.formData.bare_price = "";
      // if (this.formData.instype_id == 6) {
      //   this.needIphoneVerify = true;
      // }
      console.log(this.needIphoneVerify);
      if (
        (this.formData.instype_id !== 1 &&
          this.formData.instype_id !== 5 &&
          this.formData.instype_id !== 8 &&
          this.formData.instype_id !== 9 &&
          this.formData.instype_id !== 6 && this.formData.instype_id !== 12) ||
        this.$route.query.type == "1"
      ) {
        return;
      }
      if (
        !this.formData.brand_id ||
        !this.formData.model_id ||
        !this.formData.ram_size
      ) {
        return;
      }
      getPhonePrice({
        brand_id: this.formData.brand_id,
        model_id: this.formData.model_id,
        ram_size: this.formData.ram_size,
      })
        .then((res) => {
          if (res.data.price) {
            this.formData.bare_price = res.data.official_price;
            this.getSubInsuranceList();
          } else {
            this.$toast(res.msg || "该机型暂无价格，请联系管理员添加");
          }
        })
        .catch((err) => {
          this.$toast((err && err.msg) || "无法获取机型价格");
        });
    },
    getSubInsuranceList(isEdit = false) {
      if (!this.hasSubInsurance) {
        return;
      }
      let osType = this.$route.query.type;
      this.subInsuranceList = [];
      this.selectedSubInsurances = [];
      if (!this.isHuaWei && !this.isIphoneSmart && !this.formData.card_secret) {
        console.log("getSubInsuranceList card_secret");
        return;
      }
      //安卓需要价格
      if (osType === "2" && !this.formData.bare_price) {
        console.log("getSubInsuranceList bare_price");
        return;
      }
      //苹果需要型号
      if (osType === "1" && !this.formData.model_id) {
        console.log("getSubInsuranceList model_id");
        return;
      }
      let params = {
        type: osType,
        instype_id: this.formData.instype_id,
        card_secret: this.formData.card_secret,
      };
      if (osType === "1") {
        params.model_id = this.formData.model_id;
      }
      if (osType === "2") {
        params.bare_price = this.formData.bare_price;
      }
      if (this.isHuaWei || this.isIphoneSmart) {
        delete params.card_secret;
      }
      this.$http.checkSon(params).then((res) => {
        let list = res.data || [];
        this.subInsuranceList = list;
        let selectedSubInsurances = JSON.parse(
          JSON.stringify(this.selectedSubInsurances)
        );
        list.forEach((item) => {
          //必选子保险
          if (item.is_require) {
            if (
              !selectedSubInsurances.some(
                (sItem) => sItem.son_id === item.son_id
              )
            ) {
              //确保没重复
              selectedSubInsurances.push(item);
            }
          }
          if (isEdit && !item.is_require) {
            let selectedIds = this.selectedSubInsurancesStr.split(","); //回显的选中项
            if (selectedIds.indexOf(item.son_id + "") !== -1) {
              if (
                !selectedSubInsurances.some(
                  (sItem) => sItem.son_id === item.son_id
                )
              ) {
                //确保没重复
                selectedSubInsurances.push(item);
              }
            }
          }
          this.selectedSubInsurances = selectedSubInsurances;
        });
      });
    },
    // 注册提交
    onSubmit() {
      if (this.isHuaWei && !this.formData.video) {
        this.$toast("请上传视频");
        return;
      }
      if (this.isHuaWei && !this.selectedSubInsurances.length) {
        this.$toast("请选择子险种");
        return;
      }
      if (this.needIphoneVerify && !this.formData.tem_elsie) {
        this.$toast("请上传爱思助手/沙漏验机截图");
        return;
      }
      if (
        this.needIphoneVerify &&
        !this.formData.video &&
        this.formData.instype_id != 9
      ) {
        this.$toast("请上传视频");
        return;
      }

      //timeLimitType: 0, //华为期限类型 1 碎屏 2 延保 3 碎屏+延保
      if (
        this.isHuaWei &&
        this.timeLimitType === 1 &&
        (!this.formData.term_time_start || !this.formData.term_time_end)
      ) {
        this.$toast("请选择碎屏险期限");
        return;
      }
      if (
        this.isHuaWei &&
        this.timeLimitType === 2 &&
        (!this.formData.term_time_start2 || !this.formData.term_time_end2)
      ) {
        this.$toast("请选择延保期限");
        return;
      }
      if (
        this.isHuaWei &&
        this.timeLimitType === 3 &&
        (!this.formData.term_time_start ||
          (!this.formData.term_time_end && !this.formData.term_time_start2) ||
          !this.formData.term_time_end2)
      ) {
        this.$toast("请选择期限");
        return;
      }
      if (this.protocol) {
        this.formData.son_strs = this.selectedSubInsurances
          .map((item) => item.son_id)
          .join(",");
        this.formData.card_secret_two = "";
        this.formData.card_num_two = "";
        let arr = [];
        let arr2 = [];
        if (this.formData.card_secret2) {
          arr.push(this.formData.card_secret2);
        }
        if (this.formData.card_secret3) {
          arr.push(this.formData.card_secret3);
        }
        if (this.formData.card_secret4) {
          arr.push(this.formData.card_secret4);
        }
        if (this.formData.card_secret5) {
          arr.push(this.formData.card_secret5);
        }
        if (this.formData.card_secret6) {
          arr.push(this.formData.card_secret6);
        }
        console.log(arr);
        this.formData.card_secret_two = arr.join(",");
        if (this.formData.card_num2) {
          arr2.push(this.formData.card_num2);
        }
        if (this.formData.card_num3) {
          arr2.push(this.formData.card_num3);
        }
        if (this.formData.card_num4) {
          arr2.push(this.formData.card_num4);
        }
        if (this.formData.card_num5) {
          arr2.push(this.formData.card_num5);
        }
        this.formData.card_num_two = arr2.join(",");
        let formData = JSON.parse(JSON.stringify(this.formData));
        if (formData.instype_id_two) {
          formData.instype_id_two = formData.instype_id_two.join(",");
        }
        console.log(this.boundInsurance1);
        // if (!this.boundInsurance1) {
        //   delete formData.instype_id_two;
        // }

        if (this.$route.query.isEdit === "1") {
          this.$http.editOrder(formData).then((res) => {
            console.log(res, "submit");
            this.$myDialog({
              title: "提交成功",
            });
            this.$router.push("/home");
          });
        } else if(this.$route.query.type == 1){//苹果***
          this.$http.setOrder2({...formData,push_imei:this.iphoneAuto.push_imei,auto_exa:this.iphoneAuto.auto_exa}).then((res) => {
              console.log(res, "submit");
              this.$myDialog({
                title: "提交成功",
              });
              this.$router.push("/home");
              // this.$router.go(-1)
            });
        }else {
          if (this.is_rong === 1 && (this.showRong || (formData.instype_id_two[0] === '1' && formData.instype_id === 10)) && this.$route.query.type == 1) {//苹果的
            // if (!this.region) {
            //   this.$toast('请先选择区域')
            //   return
            // }
            // this.rong_address1 = this.region[0].text + this.region[1].text + this.region[2].text
            // this.formData.rong_address2=this.rong_address2
            // this.formData.is_rong=this.is_rong
            this.$http.setOrder2({ ...formData, rong_address1: this.rong_address1, is_rong: this.is_rong, rong_address2: this.rong_address2 }).then((res) => {
              console.log(res, "submit");
              this.$myDialog({
                title: "提交成功",
              });
              this.$router.push("/home");
              // this.$router.go(-1)
            });
          } else {
            this.$http.setOrder2(formData).then((res) => {
              console.log(res, "submit");
              this.$myDialog({
                title: "提交成功",
              });
              this.$router.push("/home");
              // this.$router.go(-1)
            });
          }
        }
      } else {
        this.$myDialog({
          type: "text",
          title: "温馨提示",
          message: "请阅读并接受是《XXXX服务协议》后提交申请",
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.random_carmi {
  color: rgb(87, 123, 181);
  font-size: 28px;
  width: 140px;
}

::v-deep .van-cell__title {
  flex: 0 3rem;
}

.add_apply {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 90vw;
    min-height: 30vh;
    max-height: 90vh;
    overflow-y: auto;
    background-color: #f6f8fe;
    border-radius: 20px;

    .wra_tittle {
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #323234;
      line-height: 60px;
      width: 90vw;
      margin: 20px auto;
      text-align: center;
    }

    div:nth-child(2) {
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 400;
      max-height: 70vh;
      overflow-y: scroll;
      width: 80vw;
      margin: 0 auto;
      color: #333333;
      line-height: 44px;
    }

    .wra_btn {
      width: 400px;
      height: 80px;
      background: #577bb5;
      box-shadow: 0px 6px 15px 1px rgba(87, 123, 181, 0.3);
      border-radius: 40px;
      text-align: center;
      color: white;
      line-height: 80px;
      margin: 30px auto;
    }
  }

  .beforeBtn {
    background: #1989fa;
    border: 1px solid #1989fa;
  }

  .afterBtn {
    background: #f0f0f0;
    border: 1px solid #f0f0f0;
    color: #999999;
  }

  ._price {
    /deep/ .van-field__control {
      color: #e86161;
    }
  }

  .img_seach {
    img {
      width: 40px;
    }
  }

  .access {
    text-align: center;
    height: 30px;
    line-height: 30px;

    img {
      width: 28px;
      height: 28px;
    }
  }

  .btn_sub {
    padding: 50px 0;
  }

  .bx_item {
    width: calc(100vw - 60px);
    margin: 20px auto;

    .box {
      line-height: 44px;
      margin-top: 20px;
      padding: 28px 25px;
      background: #f6f8fe;
      border-radius: 16px;
      color: #333333;
      font-family: PingFang SC;
      font-weight: 400;
    }
  }

  .uploder_img {
    height: 332px;
    border: 1px solid red;
  }
}

.not-purchased-from-our-shop {
  .van-cell__title {
    min-width: 8em;
  }
}

.van-switch {
  width: 2.1em;
}

.confirm-btn {
  width: calc(100vw - 150px);
  margin: 30px auto 0;
}

::v-deep .rich-text img {
  max-width: 100%;
}

.videoStyle{
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}
</style>
